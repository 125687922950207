const state = {

    wsMediaController : null,
    isMediaReady : false,
    isOnAir : false,

    canvasCascadeType : 'default',

    videoOn: false,
    microphoneOn: false,

    videoDeviceId : null,
    audioDeviceId : null,
    videoSources : [],
    audioSources : [],

    mainInputStream: null,
    additionalStreams : [],

    startStreamTrigger : 0,

    streamEvents : []

  // Devices

}

const mutations = {
    FLUSH_VARIABLES(state) {
        state.wsMediaController = null
        state.isMediaReady = false
        state.videoOn= false
        state.microphoneOn= false
        state.videoDeviceId = null
        state.audioDeviceId = null
        state.videoSources = []
    }
}

const actions = {

    STREAM_EVENT({state}, event) {
        state.streamEvents.push(event)
    },

    async GET_JANUS_START_TOKEN({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/start/${body}`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async SEND_STREAM_SOCKET_DATA({dispatch,rootState}, body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`webinar/stream/data`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

}


export default {
  namespaced:true,
    state,
    mutations,
    actions
}
